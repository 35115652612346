<template>
  <Head />
  <a
    href="#C"
    title="中央內容區塊，為本頁主要內容區"
    id="C"
    accesskey="C"
    name="C"
    class="visually-hidden-focusable"
    >:::</a
  >
  <PageBanner />
  <div
    class="container-fluid ps-0 pe-0 pt-3 pb-3 pt-lg-4 pb-lg-5 d-block position-relative"
    style="z-index: 10"
  >
    <div class="container">
      <div class="row g-2 g-lg-4">
        <div
          class="container pt-4 pt-lg-3 pb-lg-3 mt-3 rounded d-flex flex-column justify-content-center"
        >
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb border border-primary rounded p-3">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item active">數位教具</li>
              <li class="breadcrumb-item active" aria-current="page">
                VR360互動教具
              </li>
            </ol>
          </nav>
          <div class="col-12">
            <div
              class="d-flex flex-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
            >
              <h2
                class="fw-bold d-flex align-items-center rounded-top pt-3 pb-3"
              >
                <i
                  aria-hidden="true"
                  class="fa-solid fa-fw fa-cube me-3 font-1-5em"
                  style="color: #df592e"
                ></i
                ><span class="text-dark font-1-2em fw-bold">VR360互動教具</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2 mb-5 g-2">
        <h3 class="pt-2 pb-2 fw-bold">
          <i class="fa-brands fa-fw fa-youtube me-3 text-danger"></i>蕃人觀光日誌片頭 (釜山丸航行)
        </h3>
        <div class="w-100 loading mb-5" ref="target5">
          <iframe
            width="100%"
            height="700"
            src="https://www.youtube.com/embed/VEbNFcz2Dt4?si=f8aME8cmBmbuEvt6"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <h3 class="pt-2 pb-2 fw-bold">
          <i class="fa-brands fa-fw fa-youtube me-3 text-danger"></i>《世紀末的對話》釜山丸航行 3D 4K
        </h3>
        <div class="w-100 loading mb-5" ref="target5">
          <iframe
            width="100%"
            height="700"
            src="https://www.youtube.com/embed/HjpIdb4zucM?si=btmekCbsHAWheejM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  <Foot />
</template>

<script>
import Head from "@/components/layout/Head.vue";
import Foot from "@/components/layout/Foot.vue";
import PageBanner from "@/components/PageBanner.vue";

export default {
  components: {
    Head,
    Foot,
    PageBanner,
  },
  methods: {
    getPhoto(index) {
      return "/files/poker/P (" + index + ").png";
    },
  },
  watch: {
    $route() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
};
</script>

<style scoped>
.loading {
  background-image: url("/images/home/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ffffff;
}
</style>
